<template>
  <div>
    <div v-for="(field, key) in fields" :key="key">
      <div class="row" :class="[{ 'pb-2 mb-1 border-bottom': key !== (fields.length - 1) }]">
        <div class="col-md-3">
          <div class="d-flex flex-column">
            <label>Category:</label>
            <dx-select-box v-model="field.category" :element-attr="{ index: key, elName: 'category' }" @value-changed="categoryValueChanged" :show-clear-button="false" :active-state-enabled="false" :show-data-before-search="false" :show-drop-down-button="true" :open-on-field-click="true" :search-enabled="true" :value="field.bookable !== undefined && field.bookable !== null ? field.bookable : null" :data-source="serviceCategory" value-expr="id" display-expr="name">
              <dx-validator :validation-rules="[{ type: 'required', message: '' }]" />
            </dx-select-box>
          </div>
        </div>
        <div class="col-md-2">
          <div class="d-flex flex-column">
            <label>Max Bookings per Block:</label>
            <dx-text-box v-model="field.max_per_block" :element-attr="{ index: key, elName: 'max_per_block' }" :show-clear-button="false" :value="field.max_per_block" :read-only="field.category !== 'GROUP_COACHING'">
              <dx-validator :validation-rules="[{ type: 'required', message: '' }]" />
            </dx-text-box>
          </div>
        </div>
        <div class="col-md-3">
          <div class="d-flex flex-column">
            <label>Price:</label>
            <dx-text-box v-model="field.price" :element-attr="{ index: key, elName: 'price' }" placeholder="Prices according to category" :show-clear-button="false" :value="field.price">
              <dx-validator :validation-rules="[{ type: 'required', message: '' }]" />
            </dx-text-box>
          </div>
        </div>
        <div class="col-md-3">
          <div class="d-flex flex-column">
            <label>Discount:</label>
            <dx-text-box v-model="field.discount" :element-attr="{ index: key, elName: 'discount' }" :show-clear-button="false" :value="field.discount">
              <dx-validator :validation-rules="[]" />
            </dx-text-box>
          </div>
        </div>
        <div class="col-md-1 d-flex flex-column justify-content-end">
          <div class="d-flex flex-column">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-danger" class="btn-icon" @click="RemoveField(key)">
              <feather-icon icon="XIcon" />
            </b-button>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <hr />
      </div>
    </div>

    <button type="button" class="btn btn-outline-primary btn-block btn-sm" @click="AddField"><i class="fas fa-plus-circle" /> Add {{ fields.length > 0 ? 'More' : '' }} Category & Price</button>
    <button v-if="fields.length > 0" type="button" class="btn btn-outline-danger btn-block btn-sm" @click="resetField"><i class="fas fa-plus-circle" /> Clear</button>
    <div class="mb-5" />
    <div class="clearfix">&nbsp;</div>
    <div class="mb-5" />
    <div class="clearfix">&nbsp;</div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import serviceCategory from '@/modules/mixins/serviceCategory'
import moment from 'moment'

const _ = require('lodash')

export default {
  components: {
    VueTimepicker
  },
  props: {
    dataValue: {
      type: Array,
      required: false,
      default: function _default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      serviceCategory,
      fields: [],
    }
  },
  watch: {
    deep: true,
    immediate: true,
    fields(val) {
      this.$emit('dataFeature', val)
    }
  },
  mounted() {
    this.fields = Vue.util.extend([], this.dataValue)
    this.$events.$on('reset', this.resetField)
  },
  methods: {
    AddField() {
      const lastArr = this.fields.at(-1)
      if (lastArr !== undefined) {
        if (!lastArr.category) {
          this.msgShow('Please select category!', 'error')
          return false
        }

        if (!lastArr.max_per_block) {
          this.msgShow('Please input Max Bookings per Block!', 'error')
          return false
        }

        if (!lastArr.price) {
          this.msgShow('Please input price!', 'error')
          return false
        }
      }

      this.fields.push({
        category: null,
        price: _.toString(0),
        discount: _.toString(0),
        max_per_block: _.toString(0),
      })
      this.$emit('dataFeature', this.fields)
    },
    RemoveField(key) {
      this.fields.splice(key, 1)
    },
    resetField() {
      this.fields = []
    },
    categoryValueChanged(e) {
      const el = e.component.instance()
      const { index } = el.option('elementAttr')

      this.fields[index].max_per_block = (e.value === '1TO1_COACHING' ? '1' : (e.value === '2TO1_COACHING' ? '2' : this.fields[index].max_per_block))
    }
  }
}
</script>

<style>
.btn.btn-sm.btn-icon {
  padding: 0.4rem;
}
.vue__time-picker input.form-control-md {
  height: 2.8em;
}
</style>
