<template>
  <media-modal
    :visible="show"
    :centered="true"
    size="default"
  >
    <template slot="modalHeader">
      Rename item
    </template>

    <template slot="modalBody">
      <div
        v-if="loading"
        class="text-center">
        <span class="spinner icon-spinner2" />Loading...
      </div>

      <div v-else>
        <div class="modal-body">
          <div class="form-group">
            <label>Current name</label>
            <p class="form-control-static">
              {{ getItemName(currentFile) }}
            </p>
          </div>

          <div class="form-group fg-line">
            <label>New name</label>
            <input
              ref="newItemName"
              v-model="newItemName"
              type="text"
              class="form-control"
              @keyup.enter="renameItem()"
            >
          </div>

          <media-errors :errors="errors" />
        </div>
      </div>
    </template>

    <template slot="modalFooter">
      <div class="w-100">
        <div class="float-right">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="renameItem()"
          >
            Apply
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            @click="close()"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </media-modal>
</template>

<script>
import { nextTick } from '@vue/composition-api'
import axiosIns from '@/libs/axios'
import fileManagerMixin from '@/modules/mixins/fileManager'
import MediaModal from '@/views/media-manager/MediaModal.vue'
import MediaErrors from './MediaErrors.vue'

export default {
  components: {
    MediaModal,
    'media-errors': MediaErrors
  },

  mixins: [fileManagerMixin],

  props: {
    currentPath: {
      default: '',
      type: String
    },

    currentFile: {
      default() {
        return {}
      },
      type: [Object, Boolean]
    },

    /**
     * Default route prefix
     */
    prefix: {
      default: '/',
      type: String
    },

    show: {
      default: false,
      type: Boolean
    }
  },

  data() {
    return {
      errors: [],
      loading: false,
      newItemName: null,
      size: 'modal-md'
    }
  },

  watch: {
    show(val) {
      if (val) {
        nextTick(() => {
          this.$refs.newItemName.focus()
        })
      }
    }
  },

  mounted() {
    document.addEventListener('keydown', e => {
      if (this.show && e.keyCode === 13) {
        this.renameItem()
      }
    })
  },

  methods: {
    close() {
      this.errors = []
      this.newItemName = null
      this.loading = false
      this.$emit('media-modal-close')
    },

    renameItem() {
      if (!this.newItemName) {
        this.errors = ['Please provide a new name for this item']
      } else {
        this.loading = true
        const original = this.getItemName(this.currentFile)

        const data = {
          path: this.currentPath,
          original,
          newName: this.newItemName,
          type: this.isFolder(this.currentFile) ? 'Folder' : 'File'
        }

        axiosIns
          .post(`${this.prefix}browser/rename`, data)
          .then(response => {
            this.$emit('reload-folder')
            this.mediaManagerNotify(response.data.success)
            this.close()
          })
          .catch(error => {
            this.errors = error.response.data.error
              ? error.response.data.error
              : error.response.statusText
            this.loading = false
          })
      }
    }
  }
}
</script>
