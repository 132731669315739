<template>
  <div>
    <div class="row">
      <div class="col-md-8">
        <fieldset>
          <legend>Bulk Add Holiday:</legend>

          <div class="d-flex pb-1 mb-1 border-bottom">
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>Start Date:</label>
              <dx-date-box
                :value.sync="bulkAdd.startDate"
                type="date"
                display-format="yyyy-MM-dd"/>
            </div>
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>End Date:</label>
              <dx-date-box
                :value.sync="bulkAdd.endDate"
                type="date"
                display-format="yyyy-MM-dd"/>
            </div>
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>Start Time:</label>
              <vue-timepicker
                v-model="bulkAdd.startTime"
                :minute-interval="interval"
                format="HH:mm"
                close-on-complete
                drop-direction="down"
                input-width="7em"
                input-class="form-control form-control-md"/>
            </div>
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>End Time:</label>
              <vue-timepicker
                v-model="bulkAdd.endTime"
                :minute-interval="interval"
                format="HH:mm"
                close-on-complete
                drop-direction="down"
                input-width="7em"
                input-class="form-control form-control-md"/>
            </div>
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>Bookable:</label>
              <dx-select-box
                v-model="bulkAdd.bookable"
                :show-clear-button="false"
                :active-state-enabled="false"
                :show-data-before-search="false"
                :show-drop-down-button="true"
                :open-on-field-click="true"
                :search-enabled="true"
                :data-source="yesNoList"
                value-expr="id"
                display-expr="name">
                <dx-validator :validation-rules="[{ type: 'required', message: '' }]"/>
              </dx-select-box>
            </div>
            <div class="col-md-25 d-flex flex-column justify-content-end align-items-end">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary"
                class="btn-icon"
                @click="AddBulk"
              >
                <feather-icon icon="PlusIcon"/>
              </b-button>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-4">
        <fieldset>
          <legend>Bulk Delete Holiday:</legend>

          <div class="d-flex pb-1 mb-1 border-bottom">
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>Start Date:</label>
              <dx-date-box
                :value.sync="bulkDel.startDate"
                type="date"
                display-format="yyyy-MM-dd"/>
            </div>
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>End Date:</label>
              <dx-date-box
                :value.sync="bulkDel.endDate"
                type="date"
                display-format="yyyy-MM-dd"/>
            </div>
            <div class="col-md-25 d-flex flex-column justify-content-end align-items-end">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="btn-icon"
                @click="DelBulk"
              >
                <feather-icon icon="XIcon"/>
              </b-button>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <hr>
      </div>
    </div>
    <div
      v-for="(field, key) in fields"
      :key="key"
    >
      <div class="row">
        <div class="col-md-6">
          <div class="d-flex pb-1 mb-1 border-bottom">
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>Date:</label>
              <dx-date-box
                :value.sync="field.date"
                type="date"
                display-format="yyyy-MM-dd"/>
            </div>
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>Start:</label>
              <vue-timepicker
                v-model="field.start"
                :minute-interval="interval"
                format="HH:mm"
                close-on-complete
                drop-direction="down"
                input-class="form-control form-control-md"/>
            </div>
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>End:</label>
              <vue-timepicker
                v-model="field.end"
                :minute-interval="interval"
                format="HH:mm"
                close-on-complete
                drop-direction="down"
                input-class="form-control form-control-md"/>
            </div>
            <div class="col-md-55 d-flex flex-column mr-1">
              <label>Bookable:</label>
              <dx-select-box
                :ref="`holidayBookable_${key}`"
                v-model="field.bookable"
                :element-attr="{'index':key, 'elName':'availabilityFrom'}"
                :show-clear-button="false"
                :active-state-enabled="false"
                :show-data-before-search="false"
                :show-drop-down-button="true"
                :open-on-field-click="true"
                :search-enabled="true"
                :value="field.bookable !== undefined && field.bookable !== null ? field.bookable : null"
                :data-source="yesNoList"
                value-expr="id"
                display-expr="name">
                <dx-validator :validation-rules="[{ type: 'required', message: '' }]"/>
              </dx-select-box>
            </div>
            <div class="col-md-25 d-flex flex-column justify-content-end align-items-end">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                class="btn-icon"
                @click="RemoveField(key)"
              >
                <feather-icon icon="XIcon"/>
              </b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      v-if="false"
      type="button"
      class="btn btn-outline-primary btn-block btn-sm"
      @click="AddField">
      <i class="fas fa-plus-circle"/> Add Holiday
    </button>
    <button
      v-if="fields.length > 0"
      type="button"
      class="btn btn-outline-danger btn-block btn-sm"
      @click="resetField">
      <i class="fas fa-plus-circle"/> Clear
    </button>
    <div class="mb-5"/>
    <div class="clearfix">
&nbsp;
    </div>
    <div class="mb-5"/>
    <div class="clearfix">
&nbsp;
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import yesNoList from '@/modules/mixins/tipeYesNo'
import moment from 'moment'

const _ = require('lodash')

export default {
  components: {
    VueTimepicker
  },
  props: {
    dataValue: {
      type: Array,
      required: false,
      default: function _default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    interval: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      yesNoList,
      fields: [],
      bulkAdd: {
        startDate: moment().toDate(),
        endDate: moment().add(1, 'days').toDate(),
        startTime: '00:00',
        endTime: '00:10',
        bookable: 'yes'
      },
      bulkDel: {
        startDate: moment().toDate(),
        endDate: moment().add(1, 'days').toDate(),
        startTime: '00:00',
        endTime: '00:10',
        bookable: 'yes'
      }
    }
  },
  watch: {
    deep: true,
    immediate: true,
    fields(val) {
      // console.log('fields changed', val)
      this.$emit('dataFeature', val)
    },
    interval(val) {
      console.log('interval changed', val)
    }
  },
  mounted() {
    this.fields = Vue.util.extend([], this.dataValue)
    this.$events.$on('reset', this.resetField)
  },
  methods: {
    AddBulk() {
      console.log(this.bulkAdd)
      const startDate = this.$moment(this.bulkAdd.startDate, 'YYYY-MM-DD', true)
      const endDate = this.$moment(this.bulkAdd.endDate, 'YYYY-MM-DD', true)

      const startTime = this.$moment(this.bulkAdd.startTime, 'HH:mm', true)
      const endTime = this.$moment(this.bulkAdd.endTime, 'HH:mm', true)

      const { bookable } = this.bulkAdd

      if (!startDate.isValid()) {
        this.msgShow('"Start TiDateme" must be a valid date', 'error')
        return false
      }

      if (!endDate.isValid()) {
        this.msgShow('"End Date" must be a valid date', 'error')
        return false
      }

      if (startDate.isAfter(endDate)) {
        this.msgShow('"End" date has to be greater than "Start". Please change the date and try again.', 'error')
        return false
      }

      if (!startTime.isValid()) {
        this.msgShow('"Start Time" must be a valid time', 'error')
        return false
      }

      if (!endTime.isValid()) {
        this.msgShow('"End Time" must be a valid time', 'error')
        return false
      }

      if (startTime.isSameOrAfter(endTime)) {
        this.msgShow('"End" time has to be greater than "Start". Please change the time and try again.', 'error')
        return false
      }

      for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
        const idx = this.fields.findIndex(d => moment(d.date).format('YYYY-MM-DD') === m.format('YYYY-MM-DD'))

        if (idx >= 0) {
          this.fields[idx].date = m.toDate()
          this.fields[idx].start = startTime.format('HH:mm')
          this.fields[idx].end = endTime.format('HH:mm')
          this.fields[idx].bookable = bookable
        } else {
          this.fields.push({
            date: m.toDate(),
            start: startTime.format('HH:mm'),
            end: endTime.format('HH:mm'),
            bookable
          })
        }
        this.fields = _.sortBy(this.fields, 'date')
        this.$emit('dataFeature', this.fields)
      }
    },
    DelBulk() {
      console.log(this.bulkDel)
      const startDate = this.$moment(this.bulkDel.startDate, 'YYYY-MM-DD', true)
      const endDate = this.$moment(this.bulkDel.endDate, 'YYYY-MM-DD', true)

      if (!startDate.isValid()) {
        this.msgShow('"Start TiDateme" must be a valid date', 'error')
        return false
      }

      if (!endDate.isValid()) {
        this.msgShow('"End Date" must be a valid date', 'error')
        return false
      }

      if (startDate.isAfter(endDate)) {
        this.msgShow('"End" date has to be greater than "Start". Please change the date and try again.', 'error')
        return false
      }

      for (let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1, 'days')) {
        const idx = this.fields.findIndex(d => moment(d.date).format('YYYY-MM-DD') === m.format('YYYY-MM-DD'))

        if (idx >= 0) {
          this.fields.splice(idx, 1)
        }
        this.fields = _.sortBy(this.fields, 'date')
        this.$emit('dataFeature', this.fields)
      }
    },
    AddField() {
      const lastArr = this.fields.at(-1)
      if (lastArr !== undefined) {
        const startTime = this.$moment(lastArr.start, 'HH:mm', true)
        const endTime = this.$moment(lastArr.end, 'HH:mm', true)

        if (!startTime.isValid()) {
          this.msgShow('"Start" must be a valid time', 'error')
          return false
        }

        if (!endTime.isValid()) {
          this.msgShow('"End" must be a valid time', 'error')
          return false
        }

        if (startTime.isSameOrAfter(endTime)) {
          this.msgShow('"End" time has to be greater than "Start". Please change the time and try again.', 'error')
          return false
        }
      }

      this.fields.push({
        date: moment().toDate(),
        start: '00:mm',
        end: '00:mm',
        bookable: 'no'
      })
      this.$emit('dataFeature', this.fields)
    },
    RemoveField(key) {
      this.fields.splice(key, 1)
    },
    resetField() {
      this.fields = []
    }
  }
}
</script>

<style>
  .btn.btn-sm.btn-icon {
    padding: 0.4rem;
  }
  .vue__time-picker input.form-control-md {
    height: 2.8em;
  }
</style>
