<template>
  <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9 grid-toolbars">
    <b-button-group
      size="sm"
      class="mr-1"
    >
      <label class="btn btn-primary m-0">
        <feather-icon
          icon="UploadIcon"
          size="12"
          class="mr-1" />
        <span class="align-middle">Upload</span>
        <input
          class="d-none"
          name="files[]"
          type="file"
          @change="uploadFile($event.target.name, $event.target.files)"
        >
      </label>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        title="Reload"
        variant="primary"
        @click="openModalCreateFolder()"
      >
        <feather-icon
          icon="FolderPlusIcon"
          size="12"
          class="mr-1" />
        <span class="align-middle">Add Folder</span>
      </b-button>
    </b-button-group>
    <b-button-group
      size="sm"
      class="mr-1"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        title="Clear Selection"
        variant="primary"
        @click="refresh()"
      >
        <feather-icon
          icon="RefreshCwIcon"
          size="12"
          class="mr-1" />
        <span class="align-middle">Refresh</span>
      </b-button>
    </b-button-group>
    <b-button-group
      size="sm"
      class="mr-1"
    >
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="!isFile(currentFile)"
        title="Clear Selection"
        variant="primary"
        @click="openModalMoveItem()"
      >
        <feather-icon
          icon="ArrowRightIcon"
          size="12"
          class="mr-1" />
        <span class="align-middle">Move</span>
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="!isFile(currentFile)"
        title="Reload"
        variant="primary"
        @click="openModalDeleteItem()"
      >
        <feather-icon
          icon="Trash2Icon"
          size="12"
          class="mr-1" />
        <span class="align-middle">Delete</span>
      </b-button>
      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        :disabled="!isFile(currentFile)"
        title="Reload"
        variant="primary"
        @click="openModalRenameItem()"
      >
        <feather-icon
          icon="Edit3Icon"
          size="12"
          class="mr-1" />
        <span class="align-middle">Rename</span>
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
import FileManagerMixin from '@/modules/mixins/fileManager'

export default {
  components: {},
  mixins: [FileManagerMixin],
  props: {
    currentFile: {
      default() {
        return {}
      },
      type: [Object, Boolean]
    },
    currentPath: {
      default: '',
      type: String
    }
  },

  methods: {
    refresh() {
      this.$emit('reload-folder')
    },

    uploadFile(fileName, fileList) {
      this.$emit('upload-file', {
        name: fileName,
        files: fileList
      })
    },

    openModalCreateFolder() {
      this.$emit('open-modal-create-folder')
    },

    openModalMoveItem() {
      this.$emit('open-modal-move-item')
    },

    openModalDeleteItem() {
      this.$emit('open-modal-delete-item')
    },

    openModalRenameItem() {
      this.$emit('open-modal-rename-item')
    }
  }
}
</script>
