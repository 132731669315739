<template>
  <media-modal
    :visible="show"
    :centered="true"
    size="default"
  >
    <template slot="modalHeader">
      Delete item
    </template>

    <template slot="modalBody">
      <div
        v-if="loading"
        class="text-center">
        <span class="spinner icon-spinner2" />Loading...
      </div>

      <div v-else>
        <div class="modal-body">
          <div class="form-group">
            <label>Are you sure you want to delete the following item?</label>
            <p class="form-control-static">
              {{ getItemName(currentFile) }}
            </p>
          </div>
        </div>
      </div>
    </template>

    <template slot="modalFooter">
      <div class="w-100">
        <div class="float-right">
          <b-button
            variant="primary"
            size="sm"
            class="mr-1"
            @click="deleteItem()"
          >
            Delete
          </b-button>
          <b-button
            variant="secondary"
            size="sm"
            @click="close"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </template>
  </media-modal>
</template>

<script>
import axiosIns from '@/libs/axios'
import fileManagerMixin from '@/modules/mixins/fileManager'
import MediaModal from '@/views/media-manager/MediaModal.vue'

export default {
  components: {
    MediaModal
  },

  mixins: [fileManagerMixin],

  props: {
    currentPath: {
      default: '',
      type: String
    },

    currentFile: {
      default() {
        return {}
      },
      type: [Object, Boolean]
    },

    /**
     * Default route prefix
     */
    prefix: {
      default: '/',
      type: String
    },

    show: {
      default: false,
      type: Boolean
    }
  },

  data: () => ({
    loading: false,
    newItemName: null,
    size: 'modal-md'
  }),

  mounted() {
    document.addEventListener('keydown', e => {
      if (this.show && e.keyCode === 13) {
        this.deleteItem()
      }
    })
  },

  methods: {
    close() {
      this.newItemName = null
      this.loading = false
      this.$emit('media-modal-close')
    },

    deleteItem(route, data) {
      if (this.isFolder(this.currentFile)) {
        return this.deleteFolder(route, data)
      }
      return this.deleteFile(route, data)
    },

    deleteFile() {
      if (this.currentFile) {
        const data = {
          path: this.currentFile.fullPath
        }
        this.rm(`${this.prefix}browser/file`, data)
      }
    },

    deleteFolder() {
      if (this.isFolder(this.currentFile)) {
        const data = {
          path: this.currentFile.fullPath
        }
        this.rm(`${this.prefix}browser/folder`, data)
      }
    },

    rm(route, payload) {
      this.loading = true
      axiosIns.delete(route, { params: payload }).then(
        response => {
          this.$emit('reload-folder')
          this.mediaManagerNotify(response.data.success)
          this.close()
        },
        errors => {
          const error = errors.response.data.error
            ? errors.response.data.error
            : errors.response.statusText
          this.mediaManagerNotify(error, 'danger')
          this.close()
        }
      )
    }
  }
}
</script>
