<template>
  <div>
    <div
      v-for="(field, key) in fields"
      :key="key"
    >
      <div class="d-flex pb-1 mb-1 border-bottom">
        <div class="col-md-55 d-flex flex-column mr-1">
          <label>Start:</label>
          <vue-timepicker
            v-model="field.start"
            :minute-interval="interval"
            format="HH:mm"
            close-on-complete
            drop-direction="up"
            input-width="5.5em"
            input-class="form-control form-control-sm"/>
        </div>
        <div class="col-md-55 d-flex flex-column mr-1">
          <label>End:</label>
          <vue-timepicker
            v-model="field.end"
            :minute-interval="interval"
            format="HH:mm"
            close-on-complete
            drop-direction="up"
            input-width="5.5em"
            input-class="form-control form-control-sm"/>
        </div>
        <div class="col-md-25 d-flex flex-column justify-content-end align-items-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-danger"
            class="btn-icon"
            size="sm"
            @click="RemoveField(key)"
          >
            <feather-icon icon="XIcon"/>
          </b-button>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-outline-primary btn-block btn-sm"
      @click="AddField">
      <i class="fas fa-plus-circle"/> Add Timeslot
    </button>
    <button
      v-if="fields.length > 0"
      type="button"
      class="btn btn-outline-danger btn-block btn-sm"
      @click="resetField">
      <i class="fas fa-plus-circle"/> Clear
    </button>
  </div>
</template>

<script>
import Vue from 'vue'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'

export default {
  components: {
    VueTimepicker
  },
  props: {
    dataValue: {
      type: Array,
      required: false,
      default: function _default() {
        return []
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    interval: {
      type: Number,
      default: 15
    }
  },
  data() {
    return {
      fields: []
    }
  },
  watch: {
    deep: true,
    immediate: true,
    fields(val) {
      console.log('fields changed', val)
      this.$emit('dataFeature', val)
    },
    interval(val) {
      console.log('interval changed', val)
    }
  },
  mounted() {
    this.fields = Vue.util.extend([], this.dataValue)
    this.$events.$on('reset', this.resetField)
  },
  methods: {
    AddField() {
      const lastArr = this.fields.at(-1)
      if (lastArr !== undefined) {
        const startTime = this.$moment(lastArr.start, 'HH:mm', true)
        const endTime = this.$moment(lastArr.end, 'HH:mm', true)

        if (!startTime.isValid()) {
          this.msgShow('"Start" must be a valid time', 'error')
          return false
        }

        if (!endTime.isValid()) {
          this.msgShow('"End" must be a valid time', 'error')
          return false
        }

        if (startTime.isSameOrAfter(endTime)) {
          this.msgShow('"End" time has to be greater than "Start". Please change the time and try again.', 'error')
          return false
        }
      }

      this.$emit('dataFeature', this.fields)
      this.fields.push({
        start: '00:mm',
        end: '00:mm'
      })
    },
    RemoveField(key) {
      this.fields.splice(key, 1)
    },
    resetField() {
      this.fields = []
    }
  }
}
</script>

<style scoped>
  .btn.btn-sm.btn-icon {
    padding: 0.4rem;
  }
</style>
